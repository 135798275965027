import { graphql, useStaticQuery } from "gatsby";

export default function () {
  const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          ## META
          defaultTitle: title
          defaultDescription: seoDescription
          defaultShareImage: seoShareImage {
            file {
              url
            }
          }
          ## Footer
          footerCopy {
            childMarkdownRemark {
              html
            }
          }
          footerContent {
            copy {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}
