// Dependencies
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
// Hooks
import useSessionStorage from "../hooks/useSessionStorage";
import useSettings from "../hooks/useSettings";
import useWindowSize from "../hooks/useWindowSize";
import useWindowHeight from "../hooks/useWindowHeight";
// Components
import Logo, { InlineLogo } from "../components/logo";
import Menu from "../components/menu";
import Notifications from "../components/Notifications";
import Footer from "../components/footer";
// Styles
import "../css/app.scss";

const Layout = ({ children, hasName, notification }) => {

  const [hideIntro, setHideIntro] = useSessionStorage('hideIntro', false);
  const [scrollStart, setScrollStart] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  // const { footerCopy, stories, footerContent } = useSettings();
  // const { height } = useWindowSize();
  // const [height, setHeight] = useState('100vh');
  const [height] = useWindowHeight();

  useEffect(() => {
    // Initial set...
    // setHeight(window.innerHeight)
  }, []);

  const handleWheel = () => {
    if (!scrollStart) {
      setScrollStart(true)
    }
  }

  useEffect(() => {
    if (!hideIntro) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [hideIntro])

  return (
    <main style={{
      height: !hideIntro ? 'auto' : 'auto',
      overflow: !hideIntro ? 'hidden' : 'auto'
    }} onWheel={handleWheel}>
      {hasName && hideIntro && !showFooter && (
        <div className="pfix r0 t0 fixed-logo"><Link className="inline-logo-top" to="/"><InlineLogo color="#000000" /></Link></div>
      )}

      <Menu isHidden={!hideIntro} />

			{notification && !showFooter && hideIntro && <Notifications {...notification} />}

      {!hideIntro && (
        <div className="intro" style={{ height }}>
          <Logo onAnimationEnd={() => setHideIntro(true)} startWiggle={scrollStart} />
        </div>
      )}

      {/* Page content... */}
      <div id="content" style={{
        opacity: hideIntro ? 1 : 0,
        transform: hideIntro ? `translateY(0px)` : `translateY(3vh)`,
        transitionDelay: '0.3s',
        transition: `opacity 0.2s ease, transform 0.4s ease`
      }}>
        {children}
      </div>

      <Footer showFooter={showFooter} setShowFooter={setShowFooter} />
    </main>
  )
}

export default Layout
