// Dependencies
import React, { useState, useEffect, useCallback } from 'react'
// Constants
import { COLORS } from '../constants'
// Hooks
import useTimeout from '../hooks/useTimeout'
// Components
import Wiggle from '../components/wiggle'

export const BlackLogo = () => (
  <svg className="black-logo" width="647" height="244" viewBox="0 0 647 244" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd" clipRule="evenodd" fill="black">
      <path d="M0 51.5C0 21.5244 21.3809 0 49.5 0C78.1374 0 99 19.6756 99 51.5C99 81.4756 77.6191 103 49.5 103C20.8626 103 0 83.3244 0 51.5ZM87.3377 51.5C87.3377 31.4282 76.5825 10.0359 49.5 10.0359C22.4175 10.0359 11.6623 31.4282 11.6623 51.5C11.6623 67.8744 22.1584 92.9641 49.5 92.9641C76.8416 92.9641 87.3377 67.8744 87.3377 51.5Z"/>
      <path d="M120 100V2H182V11.9043H131.094V44.7447H180.679V54.6489H131.094V100H120Z"/>
      <path d="M202 100V2H264V11.9043H213.094V44.7447H262.679V54.6489H213.094V100H202Z"/>
      <path d="M286 100V2H297V100H286Z"/>
      <path d="M416.341 33.2769L405.141 34.0692C400.135 16.3744 387.354 10.0359 372.333 10.0359C344.795 10.0359 333.859 31.4282 333.859 51.5C333.859 71.3077 343.477 92.9641 372.333 92.9641C391.043 92.9641 401.584 82.1359 405.8 65.4974L417 66.8179C412.784 83.8526 401.32 103 371.147 103C342.423 103 322 83.3244 322 51.5C322 21.5244 343.741 0 372.333 0C403.956 0 413.574 19.5436 416.341 33.2769Z"/>
      <path d="M442 100V2H453V100H442Z"/>
      <path d="M476 100L513.328 2H524.672L562 100H549.469L539.577 72.3723H498.423L488.531 100H476ZM535.883 62.4681L519.132 15.8138H518.868L502.117 62.4681H535.883Z"/>
      <path d="M581 100V2H592.174V90.0957H645V100H581Z"/>
      <path d="M2 241V143H33.654C50.789 143 60.3376 153.816 60.3376 165.936C60.3376 176.753 57.4599 187.309 44.3797 189.915V190.176C54.9747 190.957 64 198.255 64 214.024C64 228.75 52.097 241 34.5696 241H2ZM12.9873 231.096H33.5232C46.211 231.096 53.0127 222.886 53.0127 213.242C53.0127 205.553 47.3882 195.649 31.038 195.649H12.9873V231.096ZM12.9873 186.527H29.3376C41.3713 186.527 48.5654 180.141 48.5654 168.673C48.5654 155.771 40.9789 152.122 29.73 152.122H12.9873V186.527Z"/>
      <path d="M85 208.979V142H95.9565V207.248C95.9565 225.491 106 233.88 121 233.88C134.696 233.88 146.043 225.358 146.043 204.984V142H157V205.117C157 231.35 140.957 244 121 244C97.1304 244 85 229.752 85 208.979Z"/>
      <path d="M243.894 168.731H232.574C232.048 160.676 225.993 151.036 210.197 151.036C194.534 151.036 187.821 160.015 187.821 169.523C187.821 176.126 191.77 182.332 209.934 185.897C234.812 190.783 246 196.858 246 214.156C246 229.078 235.47 244 209.803 244C187.426 244 175.053 232.908 174 214.685H185.188C187.163 227.758 195.982 233.964 210.329 233.964C227.309 233.964 234.154 224.06 234.154 215.213C234.154 204.385 228.099 200.951 210.592 197.254C187.821 192.5 176.764 186.162 176.764 169.523C176.764 153.281 190.059 141 210.066 141C234.417 141 241.92 154.337 243.894 168.731Z"/>
      <path d="M270 241V143H281V241H270Z"/>
      <path d="M307 241V143H318.982L374.677 223.798H374.94V143H386V241H374.018L318.323 160.202H318.06V241H307Z"/>
      <path d="M410 241V143H466V152.904H421.094V185.745H464.679V195.649H421.094V231.096H466V241H410Z"/>
      <path d="M554.894 168.731H543.574C543.048 160.676 536.993 151.036 521.197 151.036C505.534 151.036 498.821 160.015 498.821 169.523C498.821 176.126 502.77 182.332 520.934 185.897C545.812 190.783 557 196.858 557 214.156C557 229.078 546.47 244 520.803 244C498.426 244 486.053 232.908 485 214.685H496.188C498.163 227.758 506.982 233.964 521.329 233.964C538.309 233.964 545.154 224.06 545.154 215.213C545.154 204.385 539.099 200.951 521.592 197.254C498.821 192.5 487.764 186.162 487.764 169.523C487.764 153.281 501.059 141 521.066 141C545.417 141 552.92 154.337 554.894 168.731Z"/>
      <path d="M644.894 168.731H633.574C633.048 160.676 626.993 151.036 611.197 151.036C595.534 151.036 588.821 160.015 588.821 169.523C588.821 176.126 592.77 182.332 610.934 185.897C635.812 190.783 647 196.858 647 214.156C647 229.078 636.47 244 610.803 244C588.426 244 576.053 232.908 575 214.685H586.188C588.163 227.758 596.982 233.964 611.329 233.964C628.309 233.964 635.154 224.06 635.154 215.213C635.154 204.385 629.099 200.951 611.592 197.254C588.821 192.5 577.764 186.162 577.764 169.523C577.764 153.281 591.059 141 611.066 141C635.417 141 642.92 154.337 644.894 168.731Z"/>
    </g>
  </svg>
)

export const InlineLogo = ({ color }) => (
  <span className="inline-logo">
    <span style={{ color: color || 'var(--blue)' }}>O</span>
    <span style={{ color: color || 'var(--green)' }}>F</span>
    <span style={{ color: color || 'var(--teal)' }}>F</span>
    <span style={{ color: color || 'var(--pink)' }}>I</span>
    <span style={{ color: color || 'var(--red)' }}>C</span>
    <span style={{ color: color || 'var(--orange)' }}>I</span>
    <span style={{ color: color || 'var(--yellow)' }}>A</span>
    <span style={{ color: color || 'var(--purple)' }}>L</span>
    <span>{" "}</span>
    <span style={{ color: color || 'var(--red)' }}>B</span>
    <span style={{ color: color || 'var(--yellow)' }}>U</span>
    <span style={{ color: color || 'var(--blue)' }}>S</span>
    <span style={{ color: color || 'var(--orange)' }}>I</span>
    <span style={{ color: color || 'var(--purple)' }}>N</span>
    <span style={{ color: color || 'var(--green)' }}>E</span>
    <span style={{ color: color || 'var(--pink)' }}>S</span>
    <span style={{ color: color || 'var(--teal)' }}>S</span>
  </span>
)

const Logo = ({ onAnimationEnd, isMenu, startWiggle, style }) => {

  const [letterCount, setLetterCount] = useState(0);
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const countLetters = (count) => {
    setLetterCount(prev => count + prev)
  }

  useEffect(() => {
    if (startWiggle) {
      startAnimation()
    }
  }, [startWiggle])

  useEffect(useCallback(() => {
    if (letterCount === 16 && onAnimationEnd) {
      onAnimationEnd()
    }
    return () => {
      //
    }
  }, [letterCount, onAnimationEnd]), [letterCount])

  const startAnimation = () => {
    setTriggerAnimation(true)
  }

  useTimeout(() => {
    startAnimation()
  }, 4000, { persistRenders: true });


  const handlers = {
    isMenu: isMenu,
    countLetters: countLetters,
    triggerAnimation: triggerAnimation
  }

  return (
    <div className="logo" style={style} onClick={startAnimation}>

      <svg width={765} height={360} viewBox="0 0 765 360" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

        {/* Background, which gives the letters room to wiggle in */}
        <rect x="0" y="0" width="765" height="360" fill="none" />

        {/* 1. O */}
        <Wiggle fill={COLORS["blue"]} {...handlers}>
          <path d="M59,110.5 C59,80.524359 80.3808901,59 108.5,59 C137.137435,59 158,78.675641 158,110.5 C158,140.475641 136.61911,162 108.5,162 C79.8625654,162 59,142.324359 59,110.5 Z M146.337696,110.5 C146.337696,90.4282051 135.582461,69.0358974 108.5,69.0358974 C81.4175393,69.0358974 70.6623037,90.4282051 70.6623037,110.5 C70.6623037,126.874359 81.158377,151.964103 108.5,151.964103 C135.841623,151.964103 146.337696,126.874359 146.337696,110.5 Z" />
        </Wiggle>

        {/* 2. Of */}
        <Wiggle fill={COLORS['green']} {...handlers}>
          <polygon points="179 158 179 60 241 60 241 69.9042553 190.09434 69.9042553 190.09434 102.744681 239.679245 102.744681 239.679245 112.648936 190.09434 112.648936 190.09434 158" />
        </Wiggle>

        {/* 3. Off */}
        <Wiggle fill={COLORS["teal"]} {...handlers}>
          <polygon points="261 158 261 60 323 60 323 69.9042553 272.09434 69.9042553 272.09434 102.744681 321.679245 102.744681 321.679245 112.648936 272.09434 112.648936 272.09434 158" />
        </Wiggle>

        {/* 4. Offi */}
        <Wiggle fill={COLORS["pink"]} {...handlers}>
          <polygon points="345 158 345 60 356 60 356 158" />
        </Wiggle>

        {/* 5. Offic */}
        <Wiggle fill={COLORS["red"]} {...handlers}>
          <path d="M475.341193,92.2769231 L464.14147,93.0692308 C459.134535,75.374359 446.353675,69.0358974 431.332871,69.0358974 C403.79473,69.0358974 392.85853,90.4282051 392.85853,110.5 C392.85853,130.307692 402.477115,151.964103 431.332871,151.964103 C450.042996,151.964103 460.583911,141.135897 464.800277,124.497436 L476,125.817949 C471.783634,142.852564 460.320388,162 430.147018,162 C401.423024,162 381,142.324359 381,110.5 C381,80.524359 402.740638,59 431.332871,59 C462.955617,59 472.574202,78.5435897 475.341193,92.2769231 Z" />
        </Wiggle>

        {/* 6. Offici */}
        <Wiggle fill={COLORS["orange"]} {...handlers}>
          <polygon points="501 158 501 60 512 60 512 158" />
        </Wiggle>

        {/* 7. Officia */}
        <Wiggle fill={COLORS["yellow"]} {...handlers}>
          <path d="M535,158 L572.328221,60 L583.671779,60 L621,158 L608.469325,158 L598.576687,130.37234 L557.423313,130.37234 L547.530675,158 L535,158 Z M594.883436,120.468085 L578.131902,73.8138298 L577.868098,73.8138298 L561.116564,120.468085 L594.883436,120.468085 Z" />
        </Wiggle>

        {/* 8. Official */}
        <Wiggle fill={COLORS["purple"]} {...handlers}>
          <polygon points="640 158 640 60 651.174312 60 651.174312 148.095745 704 148.095745 704 158" />
        </Wiggle>

        {/* 9. Official B */}
        <Wiggle fill={COLORS["red"]} {...handlers}>
          <path d="M61,299 L61,201 L92.6540084,201 C109.78903,201 119.337553,211.816489 119.337553,223.93617 C119.337553,234.75266 116.459916,245.308511 103.379747,247.914894 L103.379747,248.175532 C113.974684,248.957447 123,256.255319 123,272.023936 C123,286.75 111.097046,299 93.5696203,299 L61,299 Z M71.9873418,289.095745 L92.5232068,289.095745 C105.21097,289.095745 112.012658,280.885638 112.012658,271.242021 C112.012658,263.553191 106.388186,253.648936 90.0379747,253.648936 L71.9873418,253.648936 L71.9873418,289.095745 Z M71.9873418,244.526596 L88.3375527,244.526596 C100.371308,244.526596 107.565401,238.140957 107.565401,226.672872 C107.565401,213.771277 99.978903,210.12234 88.7299578,210.12234 L71.9873418,210.12234 L71.9873418,244.526596 Z" />
        </Wiggle>

        {/* 10. Official Bu */}
        <Wiggle fill={COLORS["yellow"]} {...handlers}>
          <path d="M144,267.979112 L144,201 L154.956522,201 L154.956522,266.248042 C154.956522,284.490862 165,292.879896 180,292.879896 C193.695652,292.879896 205.043478,284.357702 205.043478,263.984334 L205.043478,201 L216,201 L216,264.117493 C216,290.349869 199.956522,303 180,303 C156.130435,303 144,288.751958 144,267.979112 Z" />
        </Wiggle>

        {/* 11. Official Bus */}
        <Wiggle fill={COLORS["blue"]} {...handlers}>
          <path d="M302.893967,227.730769 L291.57404,227.730769 C291.047532,219.675641 284.992687,210.035897 269.197441,210.035897 C253.533821,210.035897 246.820841,219.015385 246.820841,228.523077 C246.820841,235.125641 250.769653,241.332051 268.934186,244.897436 C293.8117,249.783333 305,255.857692 305,273.15641 C305,288.078205 294.469835,303 268.802559,303 C246.42596,303 234.053016,291.907692 233,273.684615 L244.1883,273.684615 C246.162706,286.757692 254.981718,292.964103 269.329068,292.964103 C286.308958,292.964103 293.153565,283.060256 293.153565,274.212821 C293.153565,263.384615 287.09872,259.951282 269.592322,256.253846 C246.820841,251.5 235.764168,245.161538 235.764168,228.523077 C235.764168,212.280769 249.058501,200 269.065814,200 C293.416819,200 300.919561,213.337179 302.893967,227.730769 Z" />
        </Wiggle>

        {/* 12. Official Busi */}
        <Wiggle fill={COLORS["orange"]} {...handlers}>
          <polygon points="329 299 329 201 340 201 340 299" />
        </Wiggle>

        {/* 13. Official Busin */}
        <Wiggle fill={COLORS["purple"]} {...handlers}>
          <polygon points="366 299 366 201 377.981667 201 433.676667 281.797872 433.94 281.797872 433.94 201 445 201 445 299 433.018333 299 377.323333 218.202128 377.06 218.202128 377.06 299" />
        </Wiggle>

        {/* 14. Official Busine */}
        <Wiggle fill={COLORS["green"]} {...handlers}>
          <polygon points="469 299 469 201 525 201 525 210.904255 480.09434 210.904255 480.09434 243.744681 523.679245 243.744681 523.679245 253.648936 480.09434 253.648936 480.09434 289.095745 525 289.095745 525 299" />
        </Wiggle>

        {/* 15. Official Busines */}
        <Wiggle fill={COLORS['pink']} {...handlers}>
          <path d="M613.893967,227.730769 L602.57404,227.730769 C602.047532,219.675641 595.992687,210.035897 580.197441,210.035897 C564.533821,210.035897 557.820841,219.015385 557.820841,228.523077 C557.820841,235.125641 561.769653,241.332051 579.934186,244.897436 C604.8117,249.783333 616,255.857692 616,273.15641 C616,288.078205 605.469835,303 579.802559,303 C557.42596,303 545.053016,291.907692 544,273.684615 L555.1883,273.684615 C557.162706,286.757692 565.981718,292.964103 580.329068,292.964103 C597.308958,292.964103 604.153565,283.060256 604.153565,274.212821 C604.153565,263.384615 598.09872,259.951282 580.592322,256.253846 C557.820841,251.5 546.764168,245.161538 546.764168,228.523077 C546.764168,212.280769 560.058501,200 580.065814,200 C604.416819,200 611.919561,213.337179 613.893967,227.730769 Z" />
        </Wiggle>

        {/* 16. Official Business */}
        <Wiggle fill={COLORS["teal"]} {...handlers}>
          <path d="M703.893967,227.730769 L692.57404,227.730769 C692.047532,219.675641 685.992687,210.035897 670.197441,210.035897 C654.533821,210.035897 647.820841,219.015385 647.820841,228.523077 C647.820841,235.125641 651.769653,241.332051 669.934186,244.897436 C694.8117,249.783333 706,255.857692 706,273.15641 C706,288.078205 695.469835,303 669.802559,303 C647.42596,303 635.053016,291.907692 634,273.684615 L645.1883,273.684615 C647.162706,286.757692 655.981718,292.964103 670.329068,292.964103 C687.308958,292.964103 694.153565,283.060256 694.153565,274.212821 C694.153565,263.384615 688.09872,259.951282 670.592322,256.253846 C647.820841,251.5 636.764168,245.161538 636.764168,228.523077 C636.764168,212.280769 650.058501,200 670.065814,200 C694.416819,200 701.919561,213.337179 703.893967,227.730769 Z" />
        </Wiggle>

      </svg>

    </div>
  )
}

export default Logo
