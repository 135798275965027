import React from 'react'

const Markdown = ({ className, field }) => field ? (
  <div className={className} dangerouslySetInnerHTML={{__html: field.childMarkdownRemark.html}} />
) : null

Markdown.defaultProps = {
  className: ''
}

export default Markdown
