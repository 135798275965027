// Dependencies
import React, { useState, useEffect, useRef, useCallback } from "react"
import { Link } from "gatsby"
import useClipboard from "react-use-clipboard";
// Constants
import { COLORS } from '../constants'
// Hooks
import useScroll from '../hooks/useScroll';
// Components
import Logo, { BlackLogo } from './logo'

const rand = arr => arr[Math.floor(Math.random() * arr.length)]

const CopyText = ({ className, text }) => {
  const [isCopied, setCopied] = useClipboard(text, {
    successDuration: 1000
  });
  return <button className={className} onClick={setCopied}>{isCopied ? 'Copied!' : text}</button>
}

const Menu = ({ isHidden, isOpen }) => {

  const palette = Object.values(COLORS)
  const contents = useRef()
  const [height, setHeight] = useState('1.5rem')
  const [open, setOpen] = useState(isOpen)
  const [hide, setHide] = useState(isHidden)
  const [style, setStyle] = useState({
    height: '1.5rem'
  })

  // Calculate the height of the contents for our transition...
  //
  useEffect(() => {
    setHeight(contents.current.scrollHeight)
  }, [contents])

  // Toggle it from outside the component...
  //
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  // Update CSS for transition...
  //
  const animateMenu = useCallback(() => {
    if (contents.current) {
      setStyle(prev => {
        return {
          ...prev,
          height: open ? height : '1.5rem'
        }
      })
    }
  }, [contents, setStyle, open, height]);
  useEffect(animateMenu, [open]);

  // When at bottom of page...
  const [isBottom, scrollTop] = useScroll();

  useEffect(useCallback(() => {
    if (isBottom) {
      setOpen(true)
    } else {
      setOpen(false)
    }

    // if (scrollTop > 200 && hide) {
    //   setHide(false)
    // }
    // eslint-disable-next-line
  }, [isBottom, setOpen, scrollTop, hide, setHide]), [scrollTop]);

  // Randomise colours...
  const timer = useRef();
  const changeColor = useCallback(() => {
    timer.current = setTimeout(() => {
      setStyle(prev => {
        return {
          ...prev,
          background: rand(palette)
        }
      })
      changeColor() // set again
    }, 300)
  }, [timer, setStyle, palette])
  const changeColorTimer = useCallback(() => {
    clearTimeout(timer.current)
    if (!open) {
      changeColor()
    }
    return () => {
      clearTimeout(timer.current)
    }
  }, [open, changeColor, timer])
  useEffect(changeColorTimer, [open])

  return (
    <div
      className={`menu-position ${open ? 'open' : 'closed'}`}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => !isBottom && setOpen(false)}
      onTouchEnd={() => setOpen(true)}
    >
      <div
        className={`menu ${isHidden ? 'hidden' : 'visible'}`}
        style={style}
        ref={contents}
      >
        <div className="menu-contents">
					<Link to="/">
						<BlackLogo isMenu />
					</Link>
          <div className="guttersx2">
            <p>
              Digital partner for creative brands.
            </p>
            <p>
              Studio 26 <br />
              Standard Studios <br />
              9-11 Standard Road <br />
              London NW10 6EX
            </p>
          </div>
          <nav className="guttersx2 p1">
            <div className="menu-item">
              <span className="label">Email</span>
              <CopyText className="text" text="hello@official.business" />
            </div>
            <div className="menu-item">
              <span className="label">Telephone</span>
              <span className="text">44 (0)20 8050 4217</span>
            </div>
            <a href="//instagram.com/officiallybusy" className="menu-item" target="_blank" rel="noopener noreferrer">
            <span className="label">Instagram</span>
            <span className="text">@officiallybusy</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Menu
