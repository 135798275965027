// Dependencies
import React, { useState, useEffect, useCallback } from 'react'
// Constants
const ORIGINS = [
  '50% 10%', '30% 5%', '20% 50%', '50% 50%'
]
// Helpers
const random = arr => arr[Math.floor(Math.random() * arr.length)]
const range = (max, min) => Math.random() * (max - min) + min;

const Wiggle = ({ fill, isMenu, countLetters, triggerAnimation, children }) => {

  // ANIMATION STATE
  const [animation, setAnimation] = useState({
    init: false,
    end: false,
    speed: 0.5,
    count: 10,
    origin: random(ORIGINS)
  });

  // COLOR for FILL
  const [color, setColor] = useState(isMenu ? '#000000' : fill);

  // Start the animmation, randomise values...
  //
  const startAnimation = useCallback(() => {
    if (!animation.init) {
      setAnimation({
        init: true,
        end: false,
        speed: range(0.4, 0.2),
        count: range(12, 5),
        origin: random(ORIGINS)
      })
    }
  }, [animation, setAnimation])

  // On animation end, hide letter, set animation state to END...
  //
  const handleAnimationEnd = () => {
    setColor(isMenu ? '#000000' : 'none');
    setAnimation(prev => {
      return {
        ...prev,
        end: true
      }
    });
    countLetters(1);
  }

  // When animation.end === TRUE, if we want, timeout to reset
  //
  const resetAfterAnimation = useCallback(() => {

    let timer;
    const reset = () => {
      setAnimation({
        init: false,
        end: false,
        speed: 0.5,
        count: 10,
        origin: random(ORIGINS)
      });

      if (isMenu && countLetters) {
        setColor(isMenu ? '#000000' : fill);
        countLetters(-1);
      }
    }

    if (animation.end) {
      timer = setTimeout(() => {
        reset()
      }, isMenu ? 0 : 5000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [animation, isMenu, fill, setAnimation, setColor, countLetters]);
  useEffect(resetAfterAnimation);

  // If animation is triggered from OUTSIDE component
  //
  useEffect(() => {
    if (triggerAnimation)
      startAnimation()
  }, [triggerAnimation, startAnimation])

  return (
    <g
      fill={color}
      className={animation.init ? 'wiggle' : ''}
      onMouseEnter={startAnimation}
      onTouchStart={startAnimation}
      onAnimationEnd={handleAnimationEnd}
      style={{
        animationDuration: `${animation.speed}s`,
        transformOrigin: animation.origin,
        animationIterationCount: animation.count
      }}
    >
      {children}
    </g>
  )
}

export default Wiggle
