import { useState, useEffect } from 'react';

export default function useWindowHeight() {

  const [height, set] = useState('100vh');

  const handleResize = () => {
    set(window.innerHeight)
  }

  useEffect(() => {
    set(window.innerHeight);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return [height];
}
