// Dependencies
import React, { useState, useEffect, useCallback, useRef } from 'react';
// Hooks
import useScroll from '../hooks/useScroll';
import useSettings from '../hooks/useSettings';
// Components
import Markdown from '../components/markdown';

// const Stories = ({ className, stories }) => {
//
//   const timer = useRef();
//   const [currentIndex, setCurrentIndex] = useState(0);
//
//   const changeSlide = () => {
//     setCurrentIndex(prev => {
//       if (prev === stories.length - 1) {
//         return 0
//       } else {
//         return prev + 1
//       }
//     })
//   }
//
//   const handleClick = () => {
//     changeSlide()
//   }
//
//   const setTimer = () => {
//     changeSlide();
//     clearTimeout(timer.current);
//     timer.current = setTimeout(setTimer, 4000);
//   }
//
//   useEffect(() => {
//     setTimer();
//     return () => {
//       clearTimeout(timer.current);
//     }
//   }, [])
//
//   return (
//     <div className={className} onClick={handleClick}>
//       {stories.map((story, index) => index === currentIndex && (
//         <div className="story" key={`story-${index}`}>
//           <div>
//             {story.file.contentType.includes('video') ? (
//               <video src={story.file.url} muted autoPlay loop preload="auto" playsInline />
//             ) : (
//               <img src={story.fluid.src} srcSet={story.fluid.srcSet} sizes={story.fluid.sizes} alt="" />
//             )}
//           </div>
//         </div>
//       ))}
//     </div>
//   )
// }

const Footer = ({ showFooter, setShowFooter }) => {

  const { footerContent } = useSettings();

  // When at bottom of page...
  const [isBottom] = useScroll();

  useEffect(useCallback(() => {
    if (isBottom) {
      setShowFooter(true)
    } else {
      setShowFooter(false)
    }
  }, [isBottom, setShowFooter]), [isBottom]);

  return (
    <footer className={showFooter ? 'show' : ''}>
      {/* <Stories className="footer-stories reveal" stories={stories} /> */}
      {/* <Markdown className="footer-content reveal" field={copy} /> */}
      <div className="footer-content reveal">
        {footerContent.map((entry, index) => (
          <Markdown className="" field={entry.copy} key={`footer-content-${index}`} />
        ))}
      </div>

    </footer>
  )
}

export default Footer
