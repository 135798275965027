export const COLORS = {
  red: '#F03D32',
  blue: '#176CEB',
  teal: '#2CCFDA',
  green: '#5DD669',
  pink: '#FFB6BE',
  orange: '#FF8C4B',
  yellow: '#FFC549',
  purple: '#C56CE4'
}
